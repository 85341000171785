define("discourse/plugins/discourse-custom-composer-fields/discourse/initializers/extend-for-custom-composer-fields", ["exports", "discourse/models/composer", "discourse-common/utils/decorators", "discourse/lib/plugin-api", "discourse/widgets/raw-html", "@ember/utils", "@ember/template", "virtual-dom"], function (_exports, _composer, _decorators, _pluginApi, _rawHtml, _utils, _template, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let username, topicTitle, postUrl;
  function sendPm() {
    const recipients = username;
    const reply = `${window.location.protocol}//${window.location.host}${postUrl}`;
    const title = I18n.t("composer.reference_topic_title", {
      title: topicTitle
    });

    // used only once, one less dependency
    Discourse.__container__.lookup("controller:composer").open({
      action: _composer.default.PRIVATE_MESSAGE,
      recipients,
      archetypeId: "private_message",
      draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
      draftSequence: 0,
      reply,
      title
    });
  }
  function _fmtField(field, value) {
    const format = field.format || `<div><strong>${field.name}</strong> {{value}}</div>`;
    return new _rawHtml.default({
      html: format.replace("{{value}}", value)
    });
  }
  function escape(value) {
    return (value || "").replace(/\</g, "&lt;").replace(/\>/g, "&gt;");
  }
  var _default = _exports.default = {
    name: "extend-for-composer-custom-fields",
    initialize(container) {
      var _dec, _dec2, _obj;
      _composer.default.reopen((_dec = (0, _decorators.default)("siteSettings.custom_composer_fields_enabled", "category.custom_composer_fields_enabled", "category.custom_composer_fields", "topicFirstPost"), _dec2 = (0, _decorators.default)("categoryCustomComposerFields", "categoryId"), (_obj = {
        categoryCustomComposerFields(setting_custom_composer_fields_enabled, custom_composer_fields_enabled, custom_composer_fields, topicFirstPost) {
          if (!topicFirstPost) return;
          if (!setting_custom_composer_fields_enabled) return;
          if (!custom_composer_fields_enabled) return;
          return custom_composer_fields;
        },
        ccfRequireImages(categoryCustomComposerFields, categoryId) {
          if (categoryCustomComposerFields) {
            const category = this.site.categories.findBy("id", categoryId);
            return category.ccf_require_images;
          }
        }
      }, (_applyDecoratedDescriptor(_obj, "categoryCustomComposerFields", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoryCustomComposerFields"), _obj), _applyDecoratedDescriptor(_obj, "ccfRequireImages", [_dec2], Object.getOwnPropertyDescriptor(_obj, "ccfRequireImages"), _obj)), _obj)));
      _composer.default.serializeOnCreate("custom_composer_fields");
      _composer.default.serializeToTopic("custom_composer_fields", "topic.custom_composer_fields");
      (0, _pluginApi.withPluginApi)("0.1", api => {
        api.modifyClass("controller:composer", {
          pluginId: "customcomposerfields",
          services: ["dialog"],
          origSave: null,
          _restoreCustomFields(orig) {
            if (orig.show && orig.action == "edit" && !(0, _utils.isBlank)(orig.topicId)) {
              const copy = this.keyValueStore.get("custom_composer_fields_copy");
              if (!(0, _utils.isBlank)(copy)) {
                const topic = Discourse.__container__.lookup("controller:topic");
                topic.set("model.custom_composer_fields", JSON.parse(copy));
              }
            }
          },
          _customSave(orig) {
            this.set("model.custom_composer_fields.fields", this.get("model.categoryCustomComposerFields"));
            this.origSave().then(() => {
              if (orig.action == "edit" && orig.postId) {
                this.appEvents.trigger('post-stream:refresh', {
                  id: orig.postId
                });
              }
            });
          },
          _orig() {
            const model = this.get("model");
            return {
              show: model.get("categoryCustomComposerFields"),
              action: model.action,
              topicId: model.topic ? model.topic.id : null,
              postId: model.post ? model.post.id : null,
              reqImages: model.get("ccfRequireImages")
            };
          },
          cancelComposer() {
            const orig = this._orig();
            this._super().then(() => {
              this._restoreCustomFields(orig);
            });
          },
          save() {
            this.origSave = this._super.bind(this);
            const orig = this._orig();
            if (orig.show) {
              const requiredFields = $(".ccf__fields").find("input[required]");
              const errorFields = requiredFields.filter(function () {
                return (0, _utils.isBlank)($(this).val());
              });
              if (errorFields.length > 0) {
                this.dialog.alert(I18n.t("custom_composer_fields.error_message"));
                errorFields.addClass("ccf__required-error");
                return;
              }
              if (orig.reqImages) {
                // this only works when the preview is visible (on mobile this is default not the case)
                if ($('.d-editor-preview:visible').length > 0) {
                  const images = $("#reply-control .d-editor-preview img:not([class]), #reply-control .d-editor-preview img.resizable");
                  var uploadsPresent = images.length;
                } else {
                  // fall back to substring search
                  var uploadsPresent = $('.d-editor .d-editor-input').val().includes('(upload://');
                }
                if (!uploadsPresent) {
                  const i18nPath = "custom_composer_fields.require_images.";
                  this.dialog.confirm({
                    message: (0, _template.htmlSafe)(I18n.t(i18nPath + "alert")),
                    confirmButtonLabel: i18nPath + "proceed",
                    confirmButtonClass: 'btn-danger',
                    cancelButtonLabel: i18nPath + "back",
                    didConfirm: () => {
                      this._customSave(orig);
                    },
                    didCancel: () => {
                      return;
                    }
                  });
                } else {
                  this._customSave(orig);
                }
              } else {
                this._customSave(orig);
              }
            } else {
              return this.origSave(...arguments);
            }
          }
        });
        api.createWidget("custom-composer-fields", {
          tagName: "div.ccf__dsply",
          renderField(field, val) {
            let newVal = escape(val);
            if (val == ":_login_:") {
              newVal = `<span class="ccf-trigger-signup"><u>Sign up</u> and become a member to view the URL/@handle</span>`;
            }
            if (val == ":_permission_error_:") {
              newVal = `<span class="ccf-trigger-pm">Privacy enabled. Please <u>PM the user</u> to view the URL/@handle</span>`;
            }
            return _fmtField(field, newVal);
          },
          html(attrs) {
            const post = this.findAncestorModel();
            username = post.username;
            topicTitle = post.topic.title;
            postUrl = post.url;
            const result = [];
            const fields = attrs.fields.fields || [];
            const values = attrs.fields.values || {};
            fields.forEach(field => {
              const val = values[field.name];
              let render_field;
              if (field.always_visible) {
                render_field = this.renderField(field, val);
              } else {
                if (this.siteSettings.custom_composer_fields_enabled && attrs.ccf_enabled) {
                  render_field = this.renderField(field, val);
                }
              }
              if (render_field) {
                result.push(render_field);
              }
            });
            return result;
          }
        });
        api.decorateWidget("post-contents:before", dec => {
          const postModel = dec.getModel();
          if (postModel && dec.attrs.post_number === 1) {
            const fields = postModel.get("topic.custom_composer_fields");
            if (!(0, _utils.isBlank)(fields)) {
              const ccf_enabled = postModel.get("topic.category.custom_composer_fields_enabled");
              return dec.attach("custom-composer-fields", {
                fields: fields,
                ccf_enabled
              });
            }
          }
        });
        $(document).on("click", ".ccf-trigger-signup", () => {
          const route = Discourse.__container__.lookup("route:application");
          route.send("showLogin");
        });
        $(document).on("click", ".ccf-trigger-pm", () => {
          sendPm();
        });
      });
    }
  };
});