define("discourse/plugins/discourse-custom-composer-fields/discourse/components/ccf-field", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object"], function (_exports, _decorators, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let CcfField = _exports.default = (_dec = (0, _decorators.default)("site.mobileView", "field.width.mobile", "field.width.desktop"), _dec2 = (0, _decorators.default)("field.placeholder", "field.name"), (_class = class CcfField extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "classNames", ["ccf__field"]);
      _defineProperty(this, "attributeBindings", ["customStyle:style"]);
    }
    customStyle(mobileView, mobileWidth, desktopWidth) {
      const width = mobileView ? mobileWidth || "100%" : desktopWidth || "33.33%";
      return `width: ${width}`;
    }
    format() {
      this.element.querySelectorAll("input").forEach(input => {
        input.classList.remove("ccf__required-error");
      });
    }
    placeholder(placeholder, name) {
      return placeholder || name;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "customStyle", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "customStyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "format", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "format"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "placeholder"), _class.prototype)), _class));
});